<template>
  <div class="invoice_edit_box">
    <div class="invoice_edit_title">Invoice</div>
    <div class="invoice_edit_title">发票</div>
    <div class="invoice_edit_sub">
      <div>
        <span class="bolder_text">客户：</span>
        <span @click="$intoCustomerEditPage(cusId)" style="color: #00a0e9; cursor: pointer">
          {{ form.cusName }}
        </span>
      </div>
      <div>
        <span class="bolder_text">国家：</span>
        <span>{{ form.receiveCountry + form.receiveFbaCode }}</span>
      </div>
      <div>
        <span class="bolder_text"> 渠道：</span>
        <span>{{ form.channelName }}</span>
      </div>
      <div>
        <span class="bolder_text">运单号：</span>
        <span>{{ form.runOrderNo }}</span>
      </div>
      <div>
        <span class="bolder_text">报关：</span>
        <span>{{ parseDict(form.declareType, "kd_declare_type") }}</span>
      </div>
      <div>
        <span class="bolder_text">客户备注：</span>
        <span>{{ form.cusRemark }}</span>
      </div>
    </div>
    <div class="flex_align_center">
      <span class="bolder_text" style="white-space: nowrap">备注：</span>
      <el-input
        class="remarks_input"
        size="mini"
        v-model="form.remark"
      ></el-input>
    </div>
    <div class="tab_data_box">
      <div style="display: flex">
        <div class="tab_data_two tab_data_bot">
          <div>
            <span>INVOICE No:</span>
            <span @click="handleEdit" style="color: #00a0e9; cursor: pointer">
              {{ orderNo }}
            </span>
          </div>
          <div>
            <span>运单号:</span>
            <span>{{ form.runOrderNo }}</span>
          </div>
        </div>
        <div class="tab_data_two tab_data_left tab_data_bot">
          <div>
            <span>Date:</span>
            <span>{{ form.date }}</span>
          </div>
          <div>
            <span>SOLD TO(进口商信息):</span>
          </div>
        </div>
      </div>
      <div class="tab_data_bot" style="display: flex">
        <div class="info_left">
          <div class="shipper">Shipper/Exporter</div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 10, maxRows: 15 }"
            v-model="form.exporterInfo"
            class="textarea_box bolder_text textarea_center"
          ></el-input>
        </div>
        <div class="info_right">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            v-model="form.soldtoInfo"
            class="textarea_box tab_data_bot"
          ></el-input>
          <div class="flex_align_center tab_data_bot">
            <div class="company_box">Company:</div>
            <el-input
              size="mini"
              class="padding_10 tab_data_left input_width_270 bolder_text"
              v-model="form.soldtoCompany"
            ></el-input>
          </div>
          <div class="flex_align_center tab_data_bot">
            <div class="company_box">Eori No.:</div>
            <el-input
              size="mini"
              class="padding_10 tab_data_left input_width_270 bolder_text"
              v-model="form.soldtoEorino"
            ></el-input>
          </div>
          <div class="flex_align_center tab_data_bot">
            <div class="company_box">VAT No:</div>
            <el-input
              size="mini"
              class="padding_10 tab_data_left input_width_270 bolder_text"
              v-model="form.soldtoEorino"
            ></el-input>
          </div>
          <div class="flex_align_center tab_data_bot padding_10">
            <span>SHIP TO(收件方信息):</span>
          </div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            class="textarea_box tab_data_bot bolder_text"
            v-model="form.shiptoInfo"
          ></el-input>
          <div class="padding_10 bolder_text">
            <div class="flex_align_center">
              <span class="width_60">ATTN:</span>
              <el-input size="mini" v-model="form.shiptoAttn"></el-input>
            </div>
            <div class="flex_align_center">
              <span class="width_60">Mobile:</span>
              <el-input size="mini" v-model="form.shiptoMobile"></el-input>
            </div>
            <div class="flex_align_center">
              <span class="width_60">email:</span>
              <el-input size="mini" v-model="form.shiptoEmail"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_data_bot" style="display: flex">
        <div class="width_50"></div>
        <div class="width_50 padding_10 tab_data_left">
          <span>Import&Export Client Code:</span>
        </div>
      </div>
      <div class="tab_data_bot bolder_text" style="display: flex">
        <div class="padding_10" style="width: 33%">Country of Origin</div>
        <div class="padding_10 tab_data_left" style="width: 42%">
          Country of Export
        </div>
        <div class="width_34 tab_data_left padding_10" style="width: 25%">
          Coutry of destination
        </div>
      </div>
      <div class="tab_data_bot" style="display: flex">
        <div class="padding_10" style="width: 19.7%">原产国</div>
        <div class="padding_10 tab_data_left bolder_text" style="width: 13.3%">
          CHINA
        </div>
        <div class="padding_10 tab_data_left" style="width: 17%">出口国</div>
        <div class="padding_10 tab_data_left bolder_text" style="width: 25%">
          CHINA
        </div>
        <div class="padding_10 tab_data_left" style="width: 12%">进口国</div>
        <div class="padding_10 tab_data_left bolder_text" style="width: 13%">
          {{ form.receiveCountry }}
        </div>
      </div>
      <div class="tab_data_bot bolder_text" style="display: flex">
        <div class="padding_10" style="width: 19.7%">Description of Goods</div>
        <div class="padding_10 tab_data_left" style="width: 13.3%">
          Material
        </div>
        <div class="padding_10 tab_data_left" style="width: 17%">
          Application
        </div>
        <div class="padding_10 tab_data_left" style="width: 13%">HS Code</div>
        <div class="padding_10 tab_data_left" style="width: 12%">Qty</div>
        <div class="padding_10 tab_data_left" style="width: 12%">
          Unit Value
        </div>
        <div class="padding_10 tab_data_left" style="width: 13%">
          Total Value
        </div>
      </div>
      <div class="tab_data_bot" style="display: flex">
        <div class="padding_10" style="width: 19.7%">货物描述</div>
        <div class="padding_10 tab_data_left" style="width: 13.3%">材质</div>
        <div class="padding_10 tab_data_left" style="width: 17%">用途</div>
        <div class="padding_10 tab_data_left" style="width: 13%">海关编号</div>
        <div class="padding_10 tab_data_left" style="width: 12%">数量</div>
        <div class="padding_10 tab_data_left" style="width: 12%">
          单价（USD）
        </div>
        <div class="padding_10 tab_data_left" style="width: 13%">总价(USD)</div>
      </div>
      <div
        class="tab_data_bot"
        v-for="(item, index) in form.kdOrderGoodsList"
        :key="index"
        style="display: flex"
      >
        <div class="padding_10" style="width: 19.7%">
          <el-input size="mini" v-model="item.name"></el-input>
          <el-input size="mini" v-model="item.ename"></el-input>
        </div>
        <div class="padding_10 tab_data_left" style="width: 13.3%">
          <el-input size="mini" v-model="item.material"></el-input>
        </div>
        <div class="padding_10 tab_data_left" style="width: 17%">
          <el-input size="mini" v-model="item.purpose"></el-input>
        </div>
        <div class="padding_10 tab_data_left" style="width: 13%">
          <el-input size="mini" v-model="item.hscode"></el-input>
        </div>
        <div class="padding_10 tab_data_left" style="width: 12%">
          <el-input
            size="mini"
            v-model="item.qty"
            @change="countTotal(index)"
          ></el-input>
        </div>
        <div class="padding_10 tab_data_left" style="width: 12%">
          <el-input
            size="mini"
            v-model="item.unitValue"
            @change="countTotal(index)"
          ></el-input>
        </div>
        <div class="padding_10 tab_data_left" style="width: 13%">
          {{ item.totalValue }}
        </div>
      </div>
      <div class="tab_data_bot" style="display: flex">
        <div class="padding_10 bolder_text fs_20" style="width: 33%">
          freight charge 运费
        </div>
        <div class="padding_10 tab_data_left" style="width: 17%"></div>
        <div class="padding_10 tab_data_left" style="width: 13%"></div>
        <div class="padding_10 tab_data_left" style="width: 12%"></div>
        <div class="padding_10 tab_data_left" style="width: 12%"></div>
        <div class="padding_10 tab_data_left" style="width: 13%">
          <el-input size="mini" v-model="form.freightCharge"></el-input>
        </div>
      </div>
    </div>
    <div style="display: flex">
      <div class="padding_top_5" style="width: 68%">
        <div>Remark：Totally in {{ form.num }} cartons,{{ form.qty }} pieces</div>
        <div style="display: flex">
          <span class="padding_top_5" style="white-space: nowrap">
            SHIPMENT ID:
          </span>
          <el-input
            type="textarea"
            class="textarea_box"
            v-model="form.shipmentId"
          ></el-input>
        </div>
      </div>
      <div class="padding_top_5" style="width: 19%">
        <div class="bolder_text">Total Invoice Value :</div>
        <div>总价值</div>
      </div>
      <div class="padding_10 border_no_top" style="width: 13.2%">
        {{ "$" + form.invValue }}
      </div>
    </div>
    <div class="sign_box padding_top_5 bolder_text">
      <div>Shipper's Signature & Stamp</div>
      <div>发件人签字、盖章</div>
    </div>
    <div class="padding_top_50">
      <el-button type="primary" size="mini" @click="handleSubmit">
        保存
      </el-button>
      <el-button size="mini" @click="closePage()">关闭</el-button>
      <el-button
        v-if="form.isEditInvoice == '1'"
        type="text"
        size="mini"
        @click="
          downloadExcel(
            url.downLoad + '?orderNo=' + orderNo,
            form.channelName + '-' + form.cusName + '-' + form.receiveCountry + '-' + form.actualCtn + '-' + parseDict(form.declareType,'kd_declare_type') + '-' + orderNo,
            {},
            '.xls'
          )
        "
      >
        下载
      </el-button>
    </div>
  </div>
</template>
<script>
import { getAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
export default {
  mixins: [QueryMixins],
  components: {},
  props: {},
  data() {
    return {
      dictCodes: ["kd_declare_type"],
      disableMixinMounted: true,
      orderNo: this.$route.query.orderNo,
      cusId: this.$route.query.cusId,
      form: {},
      url: {
        getInvoiceEditInfo: "/order/kdOrder/invoiceEditInfo",
        invoiceSave: "/order/kdOrder/invoiceSave",
        downLoad: "/file/jasper/downloadKdInvoice",
      },
    };
  },
  watch: {},
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    initPage() {
      getAction(this.url.getInvoiceEditInfo + "?orderNo=" + this.orderNo)
        .then((res) => {
          if (res.success) {
            this.form = res.result;
            this.$set(
              this.form,
              "isEditInvoice",
              this.$route.query.isEditInvoice
            );
          }
        })
    },
    closePage() {
      window.close();
    },
    //进入编辑页面
    handleEdit() {
      window.open("/kdorder/Edit?orderNo=" + this.orderNo);
    },
    initCusPage() {
      window.open(`/customer/Edit?id=${this.cusId}`);
    },
    countTotal(index) {
      this.form.kdOrderGoodsList[index].totalValue =
        parseFloat(this.form.kdOrderGoodsList[index].qty) *
        parseFloat(this.form.kdOrderGoodsList[index].unitValue);
      let invValue = 0;
      this.form.kdOrderGoodsList.forEach((item) => {
        invValue += parseFloat(item.totalValue);
      });
      this.form.invValue = invValue;
    },
    handleSubmit() {
      postAction(this.url.invoiceSave, this.form)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.$set(this.form, "isEditInvoice", "1");
            this.$router.replace({
              query: {
                orderNo: this.orderNo,
                cusId: this.cusId,
                isEditInvoice: "1",
              },
            });
          }
        })
    },
    handleDownLoad() {},
  },
};
</script>
<style lang="scss">
.invoice_edit_box {
  .el-input__inner,
  .el-textarea__inner {
    border-color: transparent;
    line-height: 20px;
    height: 20px;
    color: black;
    font-weight: bolder;
  }
  .el-input__inner:focus,
  .el-textarea__inner:focus {
    border-color: #2d8cf0;
  }
  .textarea_center {
    .el-textarea__inner {
      text-align: center;
    }
  }
}
</style>
<style lang="scss" scoped>
.invoice_edit_box {
  width: 700px;
  margin: 0 auto;
  padding: 50px 0;
  color: black;
  .invoice_edit_title {
    width: 100%;
    text-align: center;
    font-weight: bolder;
    font-size: 14px !important;
  }
  .invoice_edit_sub {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    div {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
  .remarks_input {
    width: 300px;
    border-bottom: 1px solid black !important;
  }
  .tab_data_box {
    margin-top: 10px;
    border: 1px solid #000;
    border-bottom: 0px;
    .tab_data_two {
      width: 50%;
      padding: 5px;
      box-sizing: border-box;
    }
    .info_left {
      width: 50%;
      .shipper {
        padding: 5px;
        box-sizing: border-box;
        border-bottom: 1px solid #000;
      }
    }
    .info_right {
      width: 50%;
      border-left: 1px solid #000;
      .company_box {
        width: 75px;
        padding-left: 5px;
        white-space: nowrap;
      }
    }
  }
  .sign_box {
    width: 250px;
    border-top: 1px solid #000;
    margin-top: 60px;
  }
  .textarea_box {
    padding: 5px;
    box-sizing: border-box;
  }
  .textarea_center {
    height: 93%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.flex_align_center {
  display: flex;
  align-items: center;
}
.padding_10 {
  padding: 3px;
}
.input_width_270 {
  width: 270px;
}
.width_60 {
  width: 50px;
}
.tab_data_left {
  border-left: 1px solid #000;
}
.tab_data_right {
  border-right: 1px solid #000;
}
.tab_data_bot {
  border-bottom: 1px solid #000;
}
.width_50 {
  width: 50%;
}
.width_33 {
  width: 33%;
}
.width_34 {
  width: 33%;
}
.width_16 {
  width: 16%;
}
.width_17 {
  width: 17%;
}
.padding_top_5 {
  padding-top: 5px;
}
.border_no_top {
  border: 1px solid #000;
  border-top: 0px;
}
.padding_top_50 {
  padding-top: 50px;
}
.bolder_text {
  font-weight: bolder;
}
.fs_20 {
  font-size: 16px !important;
}
</style>
